@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      .rounded-6xl {
          border-radius: 3rem
      }
      .rounded-5xl {
          border-radius: 2.5rem
      }
      .rounded-4xl {
          border-radius: 2rem
      }
    }
  }

body {
    font-family: 'Poppins', sans-serif;
}

